import React from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Header = () => {
  const headerQuery = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "construir-sozinho-logo-5-transparent.png" }) {
        childImageSharp {
          fixed(width: 340, height: 128) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `);
  return (
    <div className="h-32 mt-2 text-center hidden md:block">
      <Link to="/" className="mx-auto" activeClassName="mx-auto">
        <Img
          fixed={headerQuery.file.childImageSharp.fixed}
          alt="Logo de Construir Sozinho"
          title="Logo de Construir Sozinho"
        />
        {/* <img
          src={logo}
          className="h-32 object-contain"
          alt="Construir Sozinho Logo"
        /> */}
      </Link>
    </div>
  );
};

export default Header;
