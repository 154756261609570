import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "../components/styles.css";
import CategoryNavBar from "../components/CategoryNavBar";

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col bg-gray-900 min-h-screen">
      <div className="flex-1 container mx-auto">
        <Header />

        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
        ></script>
        {/* <!-- topAd_728x90 --> */}
        <ins
          className="adsbygoogle"
          style={{ display: "inline-block", width: "728px", height: "90px" }}
          data-ad-client="ca-pub-4852256985005782"
          data-ad-slot="4026394550"
        ></ins>
        <script>{`(adsbygoogle = window.adsbygoogle || []).push({});`}</script>

        <CategoryNavBar />
        <div className="px-2 sm:px-0">{children}</div>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
