import React from "react";
import { Link } from "gatsby";
const Footer = () => {
  return (
    <div className="flex items-center justify-center bg-gray-700 h-16 mt-2">
      <Link
        to="/"
        className="text-sm text-white no-underline mr-2"
        activeClassName="text-sm text-white no-underline mr-2"
      >
        © 2020 Construir Sozinho
      </Link>
      <Link
        to="/politica-de-privacidade"
        className="text-sm text-white no-underline"
        activeClassName="text-sm text-white no-underline"
      >
        Política de Privacidade
      </Link>
    </div>
  );
};

export default Footer;
