import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const SEO = ({ description, meta, title, lang, type, image }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultTitle
            titleTemplate
            defaultDescription
            author
            siteUrl
          }
        }
      }
    `
  );
  const metaDescription = description || site.siteMetadata.defaultDescription;
  const pageTitle = title || site.siteMetadata.defaultTitle;
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={pageTitle}
      titleTemplate={site.siteMetadata.titleTemplate}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: pageTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: type,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: pageTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(meta)
        .concat(
          image.length > 0
            ? {
                property: `og:image`,
                content: image,
              }
            : []
        )
        .concat(
          image.length > 0
            ? {
                property: `twitter:image`,
                content: image,
              }
            : []
        )}

      // .concat(
      //   keywords.length > 0
      //     ? {
      //         name: `keywords`,
      //         content: keywords.join(`, `),
      //       }
      //     : []
      // )
    />
  );
};

SEO.defaultProps = {
  lang: `pt-br`,
  meta: [],
  image: "",
  description: ``,
  type: "website",
};

export default SEO;
