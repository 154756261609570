import React from "react";
import { Link } from "gatsby";

const ButtonOutlined = ({ text, to }) => {
  return (
    <Link
      to={to}
      className="bg-transparent cursor-pointer hover:bg-yellow-400 text-yellow-400 font-semibold hover:text-white py-2 px-4 border border-yellow-400 hover:border-transparent rounded"
      activeClassName="bg-transparent cursor-pointer hover:bg-yellow-400 text-yellow-400 font-semibold hover:text-white py-2 px-4 border border-yellow-400 hover:border-transparent rounded"
    >
      {text}
    </Link>
  );
};

export default ButtonOutlined;
