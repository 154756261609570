import React, { useContext } from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider";

const CategoryNavBar = () => {
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  // console.log(dispatch);
  // console.log(state);
  const catNavQuery = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "construir-sozinho-logo-4-circle.png" }) {
          childImageSharp {
            fixed(width: 48, height: 48) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
        allContentfulCategory(sort: { fields: title, order: ASC }) {
          edges {
            node {
              title
              slug
            }
          }
        }
      }
    `
  );

  return (
    <div className="bg-yellow-400 md:mt-2">
      <div className="flex items-center justify-between md:justify-center px-4 py-1">
        <Link to="/" className="md:hidden" activeClassName="md:hidden">
          <Img
            fixed={catNavQuery.file.childImageSharp.fixed}
            alt="Construir Sozinho Logo"
            title="Construir Sozinho Logo"
            className="rounded-full shadow-md h-12 bg-gray-900 block"
          />
        </Link>
        {/* <div id="teste">
          <h1 className="px-2 py-2 text-white text-lg font-bold rounded bg-gray-800">
            Categoria: Construção
          </h1>
        </div> */}
        <div>
          <button
            onClick={() => dispatch({ type: "TOGGLE_DRAWER" })}
            type="button"
            aria-label="Menu de Categorias"
            className="block text-gray-500 hover:text-white focus:text-white focus:outline-none"
          >
            <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
              {state.isDrawerOpen ? (
                <path
                  fillRule="evenodd"
                  d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                />
              ) : (
                <path
                  fillRule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                />
              )}
            </svg>
          </button>
        </div>
      </div>
      {state.isDrawerOpen ? (
        <nav className="px-2 py-2">
          {catNavQuery.allContentfulCategory.edges.map((edge, i) => {
            let linkClass =
              "px-2 py-2 mt-1 block text-gray-800 hover:text-white text-lg font-bold rounded hover:bg-gray-800";
            let linkClassActive = "underline";
            return (
              <Link
                key={i}
                id={i}
                to={"/" + edge.node.slug + "/1"}
                className={linkClass}
                activeClassName={linkClassActive}
              >
                {edge.node.title}
              </Link>
            );
          })}
        </nav>
      ) : (
        ""
      )}
    </div>
  );
};

export default CategoryNavBar;
