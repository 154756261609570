import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import Img from "gatsby-image";

const SideBar = () => {
  //a foto do contentful about não está sendo usada
  const sidebarQuery = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "construir-sozinho-logo-4-circle.png" }) {
          childImageSharp {
            fixed(width: 160, height: 160) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
        allContentfulAbout {
          edges {
            node {
              text {
                text
              }
              image {
                title
                fluid(maxWidth: 200, quality: 50) {
                  src
                }
              }
            }
          }
        }
      }
    `
  );
  return (
    <>
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
      ></script>
      {/* <!-- sideTopAd_over_picture_336x280 --> */}
      <ins
        className="adsbygoogle"
        style={{ display: "inline-block", width: "336px", height: "280px" }}
        data-ad-client="ca-pub-4852256985005782"
        data-ad-slot="7118956829"
      ></ins>
      <script>{`(adsbygoogle = window.adsbygoogle || []).push({});`}</script>
      {/*  */}
      <div className="flex flex-col bg-gray-700 shadow-md rounded-md">
        <div className="text-center py-2">
          <Link
            to="/sobre-construir-sozinho"
            className="font-bold text-2xl hover:underline"
            activeClassName="font-bold text-2xl text-yellow-400"
          >
            Sobre Construir Sozinho
          </Link>
        </div>
        <div className="h-40 mx-auto">
          <Img
            fixed={sidebarQuery.file.childImageSharp.fixed}
            alt="Construir Sozinho Logo"
            title="Construir Sozinho Logo"
            className="rounded-full shadow-md"
          />
        </div>
        <div className="px-4 py-2 rounded-md text-justify">
          <p>{sidebarQuery.allContentfulAbout.edges[0].node.text.text}</p>
        </div>
      </div>
      {/*  */}
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
      ></script>
      {/* <!-- sideTopAd_336x280 --> */}
      <ins
        className="adsbygoogle"
        style={{ display: "inline-block", width: "336px", height: "280px" }}
        data-ad-client="ca-pub-4852256985005782"
        data-ad-slot="2318738169"
      ></ins>
      <script>{`(adsbygoogle = window.adsbygoogle || []).push({});`}</script>
      {/*  */}
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
      ></script>
      {/* <!-- rightSideAd_300x600 --> */}
      <ins
        className="adsbygoogle"
        style={{ display: "inline-block", width: "300px", height: "600px" }}
        data-ad-client="ca-pub-4852256985005782"
        data-ad-slot="5422731775"
      ></ins>
      <script>{`(adsbygoogle = window.adsbygoogle || []).push({});`}</script>
      {/*  */}

      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
      ></script>
      {/* <!-- rightSideAd2_300x1050 --> */}
      <ins
        className="adsbygoogle"
        style={{ display: "inline-block", width: "300px", height: "1050px" }}
        data-ad-client="ca-pub-4852256985005782"
        data-ad-slot="2166996904"
      ></ins>
      <script>{`(adsbygoogle = window.adsbygoogle || []).push({});`}</script>
    </>
  );
};
export default SideBar;
